import { Navbar } from "@components/Navbar";
import Button from "@components/Utils/Button";
import { sendPasswordResetEmail } from "firebase/auth";
import { NextSeo } from "next-seo";
import Link from "next/link";
import { useRouter } from "next/router";
import { DOMAttributes, useState } from "react";
import { auth } from "../firebase/firebase";
import { useUser } from "../utils/AuthContext";

const Login = () => {
  const router = useRouter();
  const { authWithGoogle, logInWithEmailPassword } = useUser();

  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLoginWithGoogle = async () => {
    let user = null;
    try {
      user = await authWithGoogle(true);
    } catch (error: any) {
      console.log(error);
    }

    if (user) {
      router.push("/dashboard");
    }
  };

  const handleLoginWithEmail: DOMAttributes<HTMLFormElement>["onSubmit"] =
    async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const user = await logInWithEmailPassword(email, password);
        if (user) {
          router.push("/dashboard");
        }
      } catch (error) {
        alert("Error authenticating user. Please try again");
      }
      setLoading(false);
    };

  const handleResetPassword: DOMAttributes<HTMLFormElement>["onSubmit"] =
    async (e) => {
      e.preventDefault();

      setLoading(true);
      await sendPasswordResetEmail(auth, email)
        .then(() =>
          alert(
            `Reset email sent successfully. You might need to look into spam folder or promotions tab.`
          )
        )
        .catch((err) => alert(`Error sending email : ${err}`));

      setLoading(false);
      setReset(false);
    };

  return (
    <>
      <NextSeo
        title="Login To AI Excel Bot"
        description="AI Excel Bot helps you create complex Excel formulas in seconds. Use simple English prompts to generate formulas. AI Excel Bot's formula generator uses state of the art GPT-3 AI model to give you the best results."
        canonical="https://aiexcelbot.com/login"
        openGraph={{
          url: "https://aiexcelbot.com/login",
          title: "Login To AI Excel Bot",
          description:
            "AI Excel Bot helps you create complex Excel formulas in seconds. Use simple English prompts to generate formulas. AI Excel Bot's formula generator uses state of the art GPT-3 AI model to give you the best results.",
          images: [{ url: "https://aiexcelbot.com/dashboard-hero.png" }],
          siteName: "AI Excel Bot",
        }}
        twitter={{
          handle: "@0x_gautam",
          cardType: "summary_large_image",
        }}
      />

      <main className="h-screen m-auto bg-green-50 scrollbar-thin scrollbar-thumb-gray-500 overflow-y-scroll overflow-x-hidden scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
        <div className="max-w-6xl mx-auto">
          <Navbar />

          {!reset ? (
            <section className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
              <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                    Login
                  </h1>

                  <button
                    className="flex items-center justify-center w-full btn btn-outline"
                    onClick={handleLoginWithGoogle}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      className="w-4 h-4 mr-2"
                      viewBox="0 0 48 48"
                    >
                      <defs>
                        <path
                          id="a"
                          d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                        />
                      </defs>
                      <clipPath id="b">
                        <use xlinkHref="#a" overflow="visible" />
                      </clipPath>
                      <path
                        clipPath="url(#b)"
                        fill="#FBBC05"
                        d="M0 37V11l17 13z"
                      />
                      <path
                        clipPath="url(#b)"
                        fill="#EA4335"
                        d="M0 11l17 13 7-6.1L48 14V0H0z"
                      />
                      <path
                        clipPath="url(#b)"
                        fill="#34A853"
                        d="M0 37l30-23 7.9 1L48 0v48H0z"
                      />
                      <path
                        clipPath="url(#b)"
                        fill="#4285F4"
                        d="M48 48L17 24l-4-3 35-10z"
                      />
                    </svg>
                    Login With Google
                  </button>

                  <form
                    className="space-y-4 md:space-y-6"
                    onSubmit={handleLoginWithEmail}
                  >
                    <div>
                      <label htmlFor="email" className="label">
                        <span className="label-text">Your email</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="input input-bordered w-full"
                        placeholder="name@company.com"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="password" className="label">
                        <span className="label-text">Password</span>
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="••••••••"
                        className="input input-bordered w-full"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>

                    <div>
                      <a
                        className="text-sm font-medium text-info-content hover:underline"
                        onClick={() => setReset(true)}
                      >
                        Forgot password?
                      </a>
                    </div>

                    <Button type="submit" loading={loading}>
                      Login
                    </Button>
                    <p className="text-sm font-light text-gray-500">
                      New to AI Excel Bot?{" "}
                      <Link href={"/signup"}>
                        <a className="font-medium text-info-content hover:underline">
                          Register here
                        </a>
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </section>
          ) : (
            <section className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
              <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                    Reset
                  </h1>

                  <form
                    className="space-y-4 md:space-y-6"
                    onSubmit={handleResetPassword}
                  >
                    <div>
                      <label htmlFor="email" className="label">
                        <span className="label-text">Your email</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="input input-bordered w-full"
                        placeholder="name@company.com"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>

                    <Button type="submit" loading={loading}>
                      Get Reset Password Link
                    </Button>
                  </form>
                </div>
              </div>
            </section>
          )}
        </div>
      </main>
    </>
  );
};

export default Login;
